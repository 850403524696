<template>
  <div :class="b('active-detail')">
    <div v-if="showResult" class="h100 d-flex flex-column">
      <div class="media-body">
        <v-list-item three-line>
          <v-list-item-avatar class="profile" color="grey" size="64" tile>
            <v-img
              :src="apiAddress + objSearchDoctorDetailInfo.preview"
            ></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <a
              :href="'/doctor/' + objSearchDoctorDetailInfo.url + '/' + objSearchDoctorDetailInfo.doctorId"
              target="_blank"
            >
              <h2>
                {{ objSearchDoctorDetailInfo.name }}
              </h2>
            </a>
            <v-list-item-subtitle>
              {{ objSearchDoctorDetailInfo.specialization_string }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>

      <v-divider class="ma-3" />

      <div class="flex-grow-1">
        <v-container>
          <v-row dense>
            <v-col
              cols="12"
              v-for="(data, key) in objSearchDoctorDetail"
              :key="key"
            >
              <template v-if="key === 0">
                <p v-show="data.length" class="pl-1">
                  <b>В данном филиале врач оказывает следующие услуги: </b>
                </p>
                <v-container>
                  <v-row dense>
                    <v-col
                      cols="12"
                      v-for="department in data"
                      :key="department.clinicDepartmentServiceId"
                    >
                      <v-card class="media fill-height mb-auto" hover outlined>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="7">
                              <h4 class="media-heading">
                                {{ department.serviceName }}
                              </h4>
                              <span class="mb-5">
                                Цена в рублях:
                                <b>{{
                                  department.doctorServicePrice ||
                                  department.servicePrice
                                }}</b>
                              </span>
                              <div class="clinic-name">
                                <a
                                  :href="
                                    '/department/' + department.departmentUrl + '/' + department.department_id
                                  "
                                  target="_blank"
                                >
                                  <span>{{ department.departmentName }}</span>
                                </a>
                              </div>
                              <div class="city-part">
                                <span>
                                  Район города: {{ department.cityPart }}
                                </span>
                              </div>
                            </v-col>
                            <v-col cols="12" sm="5" class="d-flex align-center">
                              <v-btn
                                outlined
                                class="mx-auto"
                                color="green"
                                @click="openDrawerFast(department)"
                              >
                                Записаться
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <template v-else>
                <v-expansion-panels flat>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Доктор также ведет прием в клинике:
                      {{ data[0].departmentName }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row dense>
                        <v-col
                          cols="12"
                          v-for="department in data"
                          :key="department.clinicDepartmentServiceId"
                        >
                          <v-card
                            class="media fill-height mb-auto"
                            hover
                            outlined
                          >
                            <v-container>
                              <v-row>
                                <v-col cols="12" sm="7">
                                  <h4 class="media-heading">
                                    {{ department.serviceName }}
                                  </h4>
                                  <span class="mt-5">
                                    Цена в рублях:
                                    <b>{{
                                      department.doctorServicePrice ||
                                      department.servicePrice
                                    }}</b>
                                  </span>
                                  <div class="clinic-name">
                                    <span>{{ department.departmentName }}</span>
                                  </div>
                                  <div class="city-part">
                                    <span>
                                      Район города: {{ department.cityPart }}
                                    </span>
                                  </div>
                                </v-col>
                                <v-col
                                  cols="12"
                                  sm="5"
                                  class="d-flex align-center"
                                >
                                  <v-btn
                                    outlined
                                    class="mx-auto"
                                    color="green"
                                    v-if="department.hiddenLk == 0"
                                    @click="openDrawerFast(department)"
                                  >
                                    Записаться
                                  </v-btn>
                                  <v-btn
                                    outlined
                                    color="red"
                                    v-if="department.hiddenLk == 1"
                                    class="fz-10"
                                  >
                                    Запись ограничена
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="flex-shrink-0">
        <div
          class="pagination text-center pb-4 pt-3"
          v-show="objPagination.actual_count > 1 && showResult"
        >
          <v-pagination
            v-model="page"
            :length="objPagination.actual_count"
            :prev-icon="icons.mdiMenuLeft"
            :next-icon="icons.mdiMenuRight"
            @input="getDoctorDetail"
          >
          </v-pagination>
        </div>
      </div>
    </div>
    <div v-else>
      <v-layout align-center justify-center>
        <v-progress-circular
          v-if="loading"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-layout>
    </div>
  </div>
</template>

<script>
import * as names from "store/names";
import isEmpty from "lodash/isEmpty";
import { mapGetters, mapActions } from "vuex";
import { mdiMenuLeft, mdiMenuRight } from "@mdi/js";

const COMPONENT_NAME = "MainResultSpecializationDetail";
export default {
  name: COMPONENT_NAME,
  data: () => ({
    loading: false,
    page: 1,
    icons: {
      mdiMenuRight,
      mdiMenuLeft,
    },
  }),
  mounted() {
    this.loading = true;
    setTimeout(() => (this.loading = false), 3000);
  },
  created() {
    this.getDoctorDetail();
  },
  computed: {
    ...mapGetters({
      objSearchDoctorDetail: names.OBJ_SEARCH_DOCTOR_DETAIL,
      objSearchDoctorDetailInfo: names.OBJ_SEARCH_DOCTOR_DETAIL_INFO,
      entityId: names.ENTITY_ID,
      objPagination: names.OBJ_SEARCH_DOCTOR_DETAIL_PAGINATION,
      depId: names.DEPARTMENT_ID,
      selectedCity: names.OBJ_SELECTED_CITY,
      docId: names.DOCTOR_ID,
    }),
    showResult() {
      return (
        !isEmpty(this.objSearchDoctorDetailInfo.preview) &&
        !isEmpty(this.objSearchDoctorDetail)
      );
    },
    apiAddress() {
      return names.API_ADDRESS;
    },
  },
  methods: {
    ...mapActions({
      mainSearchDoctorDetail: names.ACTION_MAIN_SEARCH_DOCTOR_DETAIL,
      selectedDoctorInfo: names.ACTION_SAVE_SELECTED_DOCTOR_INFO,
      saveAfterDetailState: names.ACTION_SAVE_AFTER_DETAIL,
    }),
    async saveSelectDoctorInfo(obj) {
      this.selectedDoctorInfo({
        doctorId: this.docId,
        doctorName: obj.doctorName,
        cityId: this.selectedCity.id,
        serviceName: obj.serviceName,
        serviceId: obj.serviceId,
        servicePrice: obj.servicePrice,
        myCoordinates: this.myCoordinates,
        departmentName: obj.departmentName,
        departmentId: obj.department_id,
        cityPart: obj.cityPart,
      });
    },
    getDoctorDetail() {
      this.mainSearchDoctorDetail({
        doctorId: this.docId,
        cityId: this.selectedCity.id,
        groupByDepartment: this.depId,
        page: this.page,
      });
    },

    openDrawerFast(obj) {
      this.saveSelectDoctorInfo(obj);
      this.saveAfterDetailState(true);

      this.$drawer.showDrawer(true, "Fast");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~scss/helpers/helpers";

.MainResultSpecializationDetail__active-detail {
  width: 100%;
  height: 100%;

  .media {
    &:hover {
      box-shadow: 0 2px 6px 5px #e3ecfc;
    }
  }
  .media-heading {
    font-size: 20px;
    line-height: 25px;
  }
  .clinic-name,
  .city-part {
    font-size: 15px;
    margin: 5px 0;
  }
}
</style>
